import Http from '../api/http/';

export default class CallChat extends Http {
  constructor() {
    super();
  }

  /**
   * 合力打电话根据手机号
   * @param {string} phone -  phone
   * @param {string} extenType -  extenType
   */
  callChat_heliCallByPhone() {
    return super.post('callChat/heliCallByPhone', ...arguments);
  }

  /**
   * 更新合力打电话方式
   * @param {string} extenType -  extenType
   */
  callChat_heliUpdateExtenType() {
    return super.post('callChat/heliUpdateExtenType', ...arguments);
  }

  /**
   * 获取合力外呼账号信息
   */
  callChat_getHeliCallAccountInfo() {
    return super.post('callChat/getHeliCallAccountInfo', ...arguments);
  }

  /***********打电话聊天***************/
  /**
   * 新增环信账号信息
   * @param {array} userCallChatDTO.userIds -  undefined
   * @param {integer} userCallChatDTO.userType -  undefined
   * @param {string} userCallChatDTO.companyId -  undefined
   * @param {string} id -  undefined
   * @param {integer} funtion -  undefined
   * @param {string} userAccount -  undefined
   * @param {string} userPassword -  undefined
   * @param {string} pluginId -  undefined
   * @param {string} channelId -  undefined
   * @param {string} skillGroupsName -  undefined
   */
  callChat_add() {
    return super.post('callChat/add', ...arguments, {});
  }

  /**
   * 删除环信账号信息
   * @param {string} id -  id
   */
  callChat_delete() {
    return super.post('callChat/delete', ...arguments);
  }

  /**
   * 获取账号信息列表
   * @param {integer} funtion -  undefined
   * @param {string} userAccount -  undefined
   * @param {string} pluginId -  undefined
   * @param {string} channelId -  undefined
   * @param {string} skillGroupsName -  undefined
   */
  callChat_getCallChatAccountInfoList() {
    return super.post('callChat/getCallChatAccountInfoList', ...arguments, {});
  }

  /**
   * 获取账号信息详细
   * @param {string} id -  id
   */
  callChat_getCallChatAccountInfoDetail() {
    return super.post('callChat/getCallChatAccountInfoDetail', ...arguments);
  }
  /**
   * 获取环信外呼账号信息
   */
  callChat_getHuanxinCallAccountInfo() {
    return super.post('callChat/getHuanxinCallAccountInfo', ...arguments);
  }
  /**
   * 更新环信账号信息
   * @param {array} userCallChatDTO.userIds -  undefined
   * @param {integer} userCallChatDTO.userType -  undefined
   * @param {string} userCallChatDTO.companyId -  undefined
   * @param {string} id -  undefined
   * @param {integer} funtion -  undefined
   * @param {string} userAccount -  undefined
   * @param {string} userPassword -  undefined
   * @param {string} pluginId -  undefined
   * @param {string} channelId -  undefined
   * @param {string} skillGroupsName -  undefined
   */
  callChat_update() {
    return super.post('callChat/update', ...arguments, {});
  }

  /**
   * 获取平台账号
   */
  callChat_getPlatUserList() {
    return super.post('callChat/getPlatUserList', ...arguments);
  }

  /**
   * 获取企业账号
   * @param {string} companyId -  companyId
   */
  callChat_getCompanyUserList() {
    return super.post('callChat/getCompanyUserList', ...arguments);
  }
  /**
   * 根据类型获取账号信息
   * @param {integer} function -  1-环信外呼 2-环信聊天
   */
  callChat_getCallChatInfoList() {
    return super.post('callChat/getCallChatInfoList', ...arguments);
  }
  /**
   * 获取当前人登录聊天账号信息
   */
  callChat_getCurrentUserChatFunctionCallChatId() {
    return super.post('callChat/getCurrentUserChatFunctionCallChatId', ...arguments);
  }
  /**
   * 下载文件
   * @returns
   */
  callChat_getSipClientUrl() {
    return super.get('callChat/getSipClientUrl', ...arguments);
  }

  /**
   * 登录客服坐席端
   * @returns
   */
  callChat_getCallChatLoginCode() {
    return super.post('callChat/getCallChatLoginCode', ...arguments);
  }
}
