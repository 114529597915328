import Http from '../api/http/';

export default class InstantPush extends Http {
  constructor() {
    super();
  }
  /**
   * 获取实时推送消息
   */
  instantPush_getInstantPushInfo() {
    return super.post('instantPush/getInstantPushInfo', ...arguments);
  }
  /**
   * 获取菜单权限
   */
  instantPush_getMenuPermissionList() {
    return super.post('instantPush/getMenuPermissionList', ...arguments);
  }
}
