import Http from '../api/http/';

export default class User extends Http {
  constructor() {
    super();
  }
  user_getUser2PList() {
    return super.post('user/getUser2PList', ...arguments);
  }
  //获取指定访客列表成功
  user_getUser2CPage() {
    return super.post('user/getUser2CPage', ...arguments);
  }
  /**
   * 认证失败列表
   * @param {string} userName -  用户姓名
   * @param {string} phone -  手机号码
   * @param {string} startTime -  开始时间
   * @param {string} endTime -  开始时间
   * @param {integer} rows -  rows
   * @param {integer} page -  page
   */
  user_certificateFailList() {
    return super.post('user/certificateFailList', ...arguments);
  }
  /**
   * 人工认证申请详情
   * @param {string} applyId -  申请id
   */
  user_certificateApplyDetail() {
    return super.post('user/certificateApplyDetail', ...arguments);
  }

  /**
   * 人工认证申请列表
   * @param {string} userName -  用户姓名
   * @param {integer} status -  状态
   * @param {string} phone -  手机号码
   * @param {integer} rows -  rows
   * @param {integer} page -  page
   */
  user_certificateApplyList() {
    return super.post('user/certificateApplyList', ...arguments);
  }

  /**
   * 处理人工认证申请
   * @param {Array} req -  req
   */
  user_handleCertificateApply() {
    return super.post('user/handleCertificateApply', ...arguments);
  }
  /**
   * 修改人工认证申请
   * @param {Array} certificateApplyDto -  certificateApplyDto
   */
  user_updateCertificateApply() {
    return super.post('user/updateCertificateApply', ...arguments);
  }
  //报告审核获取用户信息
  user_userInfo2P() {
    return super.post('user/userInfo2P', ...arguments);
  }
  //获取用户任务信息
  user_getUserTaskAmount() {
    return super.post('user/getUserTaskAmount', ...arguments);
  }

  /**
   * 获取当前用户基本信息
   */
  user_getUserInfo() {
    return super.post('user/getUserInfo', ...arguments);
  }

  /**
   * 修改密码
   * @param {string} oldPwd -  旧密码
   * @param {string} newPwd -  新密码
   */
  user_changePwd() {
    return super.post('user/changePwd', ...arguments);
  }

  /**
   * 微信网页绑定
   * @param {string} code -  code
   * @param {Array} model -  model
   */
  user_user_bindWeixinWeb() {
    return super.post('user/user/bindWeixinWeb', ...arguments);
  }

  /**
   * p端管理员修改自己的个人资料
   * @param {Array} req -  req
   */
  user_updateMyInfo2P() {
    return super.post('user/updateMyInfo2P', ...arguments);
  }

  // 海外用户银行卡换绑申请列表
  user_changeBankCardApplyList() {
    return super.post('user/changeBankCardApplyList', ...arguments);
  }

  // 海外用户银行卡换绑申请详情
  user_changeBankCardApplyDetail() {
    return super.post('user/changeBankCardApplyDetail', ...arguments);
  }

  //处理换绑银行卡申请
  user_handleChangeBankCardApply() {
    return super.post('user/handleChangeBankCardApply', ...arguments, {});
  }

  //访客注销名单
  user_getCancellationList() {
    return super.post('user/getCancellationList', ...arguments);
  }

  //获取用户推荐列表
  user_getRecommendList() {
    return super.post('user/getRecommendList', ...arguments);
  }

  //获取用户人员推荐分布
  user_getRecommendDistribution() {
    return super.post('user/getRecommendDistribution', ...arguments);
  }

  //根据商圈id获取用户信息
  user_getListByBusinessId() {
    return super.post('user/getListByBusinessId', ...arguments, {});
  }

  //门店已匹配中奖用户
  user_getStoreDrawUserList() {
    return super.post('user/getStoreDrawUserList', ...arguments, {});
  }

  /**
   * p端访客用户修改手机号
   * @param {Array} userEdit2CDto -  userEdit2CDto
   */
  user_modifyUserPhone2C() {
    return super.post('user/modifyUserPhone2C', ...arguments);
  }

  /**
   * 导出企业下的门店
   * @param {string} filter -  姓名/身份证
   * @param {string} cancelTimeStart -  注销起始时间
   * @param {string} cancelTimeEnd -  注销结束时间
   */
  user_exportUserCancelList() {
    return super.get('user/exportUserCancelList', ...arguments);
  }

  /**
   * 人工认证清除psc缓存
   * @param {string} id -  id
   */
  user_cleanPscRecord() {
    return super.post('user/cleanPscRecord', ...arguments);
  }

  /**
   * p端管理员修改自己的手机号
   * @param {Array} req -  req
   */
  user_updateMyPhone2P() {
    return super.post('user/updateMyPhone2P', ...arguments);
  }

  /**
   * 获取嘉宾下拉
   * @returns
   */
  user_getUser2CNoPage() {
    return super.post('user/getUser2CNoPage', ...arguments);
  }

  /**
   * 检测用户是否存在并认证
   * @param {string} tel -  手机号
   */

  user_checkUserAuthentication() {
    return super.post('user/checkUserAuthentication', ...arguments);
  }

  /**
   * 获取指定访客列表
   * @param {string} realName -  真实姓名
   * @param {string} phone -  手机号
   * @param {integer} page -  页数
   * @param {integer} rows -  页面容量
   */

  user_getAppUserListPage() {
    return super.post('user/getAppUserListPage', ...arguments);
  }
  /**
   * 获取访客列表
   * @param {Array} user2CListDTO -  user2CListDTO
   */
  user_getUser2CList() {
    return super.post('user/getUser2CList', ...arguments, {});
  }

  //修改用户评论状态
  user_updateUser2CComment() {
    return super.post('user/updateUser2CComment', ...arguments);
  }
  //重置认证次数
  user_resetCertificateNum() {
    return super.post('user/resetCertificateNum', ...arguments);
  }
  //手动清除PCS认证缓存
  psc_cleanRecord() {
    return super.post('psc/cleanRecord', ...arguments);
  }
  //通过ID把用户加入黑名单
  user_addBlackListByUserId() {
    return super.post('user/addBlackListByUserId', ...arguments);
  }
  //将用户从黑名单中移除
  user_removeFromBlackListByUserId() {
    return super.post('user/removeFromBlackListByUserId', ...arguments);
  }
  //通过用户ID获取到关联列表、
  user_getRelateUsers() {
    return super.post('user/getRelateUsers', ...arguments);
  }
  //P端添加两个用户的关联关系
  user_addUserRelation() {
    return super.post('user/addUserRelation', ...arguments, {});
  }
  //P端移除两个用户的关联关系
  user_removeUserRelation() {
    return super.post('user/removeUserRelation', ...arguments, {});
  }
  //c端用户简要信息搜索
  user_userBriefSearch() {
    return super.post('user/userBriefSearch', ...arguments);
  }
  //关联记录
  user_applicantList() {
    return super.post('user/applicantList', ...arguments);
  }
  //积分记录
  user_getActiveScoreList() {
    return super.post('user/getActiveScoreList', ...arguments);
  }
  //信用分记录
  user_getCreditScoreList() {
    return super.post('user/getCreditScoreList', ...arguments);
  }
  //用户签到记录
  user_dailySignDetail2P() {
    return super.post('user/dailySignDetail2P', ...arguments);
  }
  //重置C端用户
  user_resetAccount() {
    return super.post('user/resetAccount', ...arguments);
  }
  //访客列表
  user_getUser2CBasicList() {
    return super.post('user/getUser2CBasicList', ...arguments);
  }
  //访客用户赠送积分
  user_giveActiveScore() {
    return super.post('user/giveActiveScore', ...arguments, {});
  }
  //访客用户添加标签
  user_userLabelMark() {
    return super.post('user/userLabelMark', ...arguments);
  }
  //用户已经添加的标签
  user_allUserLabelsSelected() {
    return super.post('user/allUserLabelsSelected', ...arguments);
  }
  //移除用户标签
  user_userLabelRemove() {
    return super.post('user/userLabelRemove', ...arguments);
  }
  //查看用户备注
  user_getRemarkByUserId() {
    return super.post('user/getRemarkByUserId', ...arguments);
  }
  //修改C端用户备注
  user_updateRemark2C() {
    return super.post('user / updateRemark2C', ...arguments, {});
  }
  //推荐关系
  user_relationTree() {
    return super.post('user/relationTree', ...arguments);
  }
  //修改用户状态
  user_updateUser2CStatus() {
    return super.post('user/updateUser2CStatus', ...arguments);
  }
  //获取所有的企业负责人
  user_getAllEnterprisePrincipal() {
    return super.post('user/getAllEnterprisePrincipal');
  }
  /**
   * 解除风险账户
   * @param {string} userId -  userId
   */

  user_relieveUserRiskAccount() {
    return super.post('user/relieveUserRiskAccount', ...arguments);
  }
  /**
   * 获取员工
   * @param {string} userId -  userId
   */

  user_getNormalUserList() {
    return super.post('user/getNormalUserList', ...arguments);
  }
  /**
   * 获取p端用户
   */

  user_getPUserList() {
    return super.post('user/getPUserList', ...arguments);
  }
  /**
   * 是否是可疑用户
   * @returns
   */
  user_isHaveMutiLoginAccount() {
    return super.post('user/isHaveMutiLoginAccount', ...arguments);
  }
  /**
   * 通过用户id查找用户的全部企业封禁记录
   * @param {string} userId -  userId
   * @param {integer} rows -  rows
   * @param {integer} page -  page
   */
  user_getAllCompanyForbiddenRecordsByUserId() {
    return super.post('user/getAllCompanyForbiddenRecordsByUserId', ...arguments);
  }
  /**
   * 企业禁用解禁
   * @param {Array} req -  req
   */
  user_removeCompanyForbidden() {
    return super.post('user/removeCompanyForbidden', ...arguments, {});
  }
  /**
   * 企业禁用详情
   * @param {Array} req -  req
   */
  user_getUserCompanyForbiddenDetail() {
    return super.post('user/getUserCompanyForbiddenDetail', ...arguments, {});
  }
  /**
   * 修改企业禁用
   * @param {Array} req -  req
   */
  user_updateUserCompanyForbidden() {
    return super.post('user/updateUserCompanyForbidden', ...arguments, {});
  }
  /**
   * 增加一条新的用户企业禁用
   * @param {Array} req -  req
   */
  user_addUserCompanyForbidden() {
    return super.post('user/addUserCompanyForbidden', ...arguments, {});
  }
  /***
   * 判断uid是否存在
   */
  user_judgeUserId() {
    return super.post('user/judgeUserId', ...arguments);
  }
}
